import Vue from 'vue'
import App from '@/App.vue'
import router from '@/config/router'
import store from '@/config/store'
import axios from 'axios'
import VueI18n from 'vue-i18n'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import VueMatomo from 'vue-matomo'
import Inview from 'vueinview'
import VueDummy from "vue-dummy"
import VueDragscroll from 'vue-dragscroll' // https://vue-dragscroll.donfalcon.com/
import ZoomOnHover from "vue-zoom-on-hover" // https://github.com/174n/vue-zoom-on-hover#readme
import currentEnv from '@/helper/getCurrentEnv'
import mqBreakpoints from '@/config/mqBreakpoints'
import EventBus from '@/helper/EventBus.js'

Vue.config.productionTip = false

// Tell Vue that the web component is present.
//Vue.config.ignoredElements = [/img-comparison-slider/]

// on error + warn: play beep sound and show errorBadge
// see: https://vuejs.org/v2/api/#errorHandler
// see: https://vuejs.org/v2/api/#warnHandler
const warnAndErrorHandler = (err, vm, info) => {
	// err: error trace
	// vm: component in which error occured
	// info: Vue specific error information such as lifecycle hooks, events etc.

	// TODO: Perform any custom logic or log to server
	const componentName = vm.$options.name

	// remove eventually previously added errorBadge
	const prevErrorBadge = document.body.querySelector('errorBadge')
	if (prevErrorBadge) document.body.removeChild(prevErrorBadge)
	// Show red dot as visible error indicator
	const badgeHeight = '150px'
	const errorBadgeElm = document.createElement('div')
	errorBadgeElm.classList.add("errorBadge")
	errorBadgeElm.style.cssText = `
		position: fixed;
		border-radius: 50%;
		top: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		left: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		width: ${badgeHeight};
		height: ${badgeHeight};
		opacity: 0.85;
		z-index: 100000;
		background: red;
	`;
	document.body.appendChild(errorBadgeElm);

	console.group('%cerrorHandler() •', 'color : red', componentName)
	console.error('err:', err)
	console.log('%cvm:', 'color : red', vm)
	console.log('%cinfo:', 'color : red', info)
	console.groupEnd()

	const a = new AudioContext() // browsers limit the number of concurrent audio contexts, so you better re-use'em

	function beep(vol, freq, duration) {
		const v = a.createOscillator()
		const u = a.createGain()
		v.connect(u)
		v.frequency.value = freq
		v.type = "square"
		u.connect(a.destination)
		u.gain.value = vol * 0.01
		v.start(a.currentTime)
		v.stop(a.currentTime + duration * 0.001)
	}

	beep(10, 320, 150)
}
if( 'live' !== currentEnv.name ){
	Vue.config.warnHandler = warnAndErrorHandler
	Vue.config.errorHandler = warnAndErrorHandler
}

Vue.use(ZoomOnHover)
Vue.use(VueDragscroll)
Vue.use(VueDummy)
Vue.use(VueI18n)
Vue.use(VueAxios, axios)
Vue.use(VueLodash, {lodash: lodash})
Vue.use(VShowSlide)
Vue.use(Inview)
Vue.use(VueMq, mqBreakpoints)

// Setup VueMatomo
if ('live' === currentEnv.name) {
	Vue.use(VueMatomo, require('@/config/vueMatomo.live').default)
}
if ('stage' === currentEnv.name) {
	Vue.use(VueMatomo, require('@/config/vueMatomo.stage').default)
}

// Setup i18n
// See: http://kazupon.github.io/vue-i18n/started.html
const i18n = new VueI18n({
	locale: 'de', // set default locale
	fallbackLocale: 'en',
	messages: {
		de: require('./config/i18n').default.de,
		en: require('./config/i18n').default.en,
		es: require('./config/i18n').default.es,
	},
})
// Enable hot updates for i18n
// See: http://kazupon.github.io/vue-i18n/guide/hot-reload.html
if( module.hot ){
	module.hot.accept(['./config/i18n'], () => {
		i18n.setLocaleMessage('de', require('./config/i18n').default.de)
		i18n.setLocaleMessage('en', require('./config/i18n').default.en)
		i18n.setLocaleMessage('es', require('./config/i18n').default.es)
	})
}

// Setup and mount root
new Vue({
	i18n,
	router,
	store,
	data() {
		return {
			currentEnv: currentEnv,
			mqBreakpoints: mqBreakpoints,
		}
	},
	watch: {
		'$route.params.lang'(to, from) {	// set this.$i18n.local
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)

			this.$i18n.locale = routeLangIsValid ? routeLang : this.$i18n.fallbackLocale
		}
	},
	computed: {
		isBelowDt(){
			const validMqs = [ 'xs', 'sm', 'md' ]

			return validMqs.includes(this.$mq)
		},
		isBelowMd(){
			const validMqs = [ 'xs', 'sm' ]

			return validMqs.includes(this.$mq)
		},
	},
	methods: {
		getPathFromUrl(url) {
			if (!url) return

			return url.replace(/^.*\/\/[^\/]+/, '')
		},
	},
	created() {
		/*
		console.log('currentEnv:', currentEnv)
		console.log('mqBreakpoints:', mqBreakpoints)
		*/
	},
	mounted() {
		// Set $i18n.locale from current route param
		// if router lang is not valid redirect to fallbackLang en
		this.$router.onReady((to, from, next, doLog = false) => {
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes( routeLang )
			const fallbackLang = this.$i18n.fallbackLocale

			if( doLog ){
				console.group('main.js • $router.onReady()')
				console.log('routeLang:', routeLang)
				console.log('routeLangIsValid:', routeLangIsValid)
				console.log('to:', to)
			}

			// set lang to en if router lang isnt valid
			if( !routeLangIsValid ){
				//const newPath = '/' + fallbackLang + '/' + to.fullPath.replace( '//', '/' )

				if (doLog) {
					console.log('router lang isnt valid')
					console.log('>>> set lang to:', fallbackLang)
				}

				//this.$router.replace( newPath )
				this.$i18n.locale = fallbackLang
			}
			// set locale if router lang is valid
			else {
				this.$i18n.locale = routeLangIsValid ? routeLang : fallbackLang
			}

			if (doLog) {
				console.groupEnd()
			}
		})

		// Set html lang attribute
		// 1)	on load
		const htmlElm = document.querySelector('html')
		this.$router.onReady(() => {
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)

			//console.log('set lang to:', htmlElm)
			htmlElm.lang = routeLangIsValid ? routeLang : this.$i18n.fallbackLocale
		})
		// 2)	on route change
		this.$router.afterEach((to, from) => {
			const routeLang = this.$route.params.lang
			const routeLangIsValid = i18n.availableLocales.includes(routeLang)

			//console.log('set lang to:', htmlElm)
			htmlElm.lang = routeLangIsValid ? routeLang : this.$i18n.fallbackLocale
		})
	},
	render: h => h(App)
}).$mount('#app')
