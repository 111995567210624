/**
 * Strips protokol and hostname from url, returns just the path
 *   basename('./test/index.js') > index.js
 *   basename('./test/index.js', false) > index *
 *
 * @param url : string with url
 * @returns returns the url pathname
 *
 * 2023-06-30  bugfix: added has to return
 */

function getUrlWithoutHostname(url) {
	let theReturn = ''

	try {
		let _url = new URL(url)

		theReturn = _url.pathname + _url.hash
	} catch (e) {
		//
	}

	return theReturn
}

export default getUrlWithoutHostname
