/**
 * In dieser Datei werden die verschiedenen Environments für die App angelegt.
 * Die Environments werden anhand des jeweiligen hostname unterschieden.
 * Es kann beliebig viele Pairs hinzugefügt werden, Bedingung ist lediglich
 * der hostname.
 *
 * Das ist z.B. sinnvoll, wenn die locale Dev-Umgebung
 * eine andere Rest-API benötigt als die Live-Umgebung.
 */

export default [
	{
		name : 'local',
		hostname : 'localhost',
	},
	{
		name : 'local-2',
		hostname : 'digiguide.local',
	},
	{
		name : 'stage',
		hostname : 'digiguide-staging.opelvillen.de',
	},
	{
		name : 'stage',
		hostname : 'stage.interior-rheinmain.de',
	},
	{
		name : 'live',
		hostname : 'digiguide.opelvillen.de',
	},
	{
		name : 'live',
		hostname : 'interior-rheinmain.de',
	},
]
