<!--

	Diese Componente stellt die Startseite für die INTERIOR-Website dar.
	Es werden alle StoryGuides als Click-Kacheln ausgegeben.

	Beispiel Code:
		<InteriorHome></InteriorHome>

	2024-05-22	init

-->

<template>
	<div class="InteriorHome" :class="elmClasses">
		<div class="InteriorHome__inner">

			<div class="InteriorHome__cover maxWidth maxWidth--app hSpace hSpace--app">
				<MhAspectRatioWrapper :aspectRatio="$root.isBelowMd ? '329:451' : '2419:1373'">
					<div class="InteriorHome__coverImage"></div>
				</MhAspectRatioWrapper>
			</div>

			<div class="InteriorHome__introText maxWidth maxWidth--app hSpace hSpace--app">
				<div class="InteriorHome__introTextInner">
					Auf Initiative der Stiftung Opelvillen haben sich sechs Kultureinrichtungen im Rhein-Main-Gebiet zum Kooperationsprojekt INTERIOR zusammengeschlossen. Die unterschiedlichen Häuser verbindet, dass sie nicht als Museen oder Ausstellungsorte gebaut worden sind. Es ist unser gemeinsames Ziel, die Ursprünge unserer verschiedenen Gebäudeensembles zu beleuchten und lebendig zu halten.
				</div>
			</div>

			<ul class="InteriorHome__guidesList maxWidth maxWidth--app hSpace hSpace--app">
				<li class="InteriorHome__guidesListItem" v-for="post in guidePosts" :key="post.id">
					<router-link  class="InteriorHome__guide" :to="getUrlWithoutHostname( post.link )">
						<MhAspectRatioWrapper class="InteriorHome__guideThumbnailWrapper" :aspectRatio="'83:55'">
							<img class="InteriorHome__guideThumbnail" :src="_.get( post, 'acf.postThumbnail.sizes.medium' )" v-if="_.get( post, 'acf.postThumbnail.sizes.medium' )" />
						</MhAspectRatioWrapper>
						<div class="InteriorHome__guideTitle" v-html="post.title.rendered"></div>
					</router-link>
				</li>
			</ul>

			<div class="InteriorHome__logos">
				<div class="InteriorHome__logosInner maxWidth maxWidth--app hSpace hSpace--app">
					<MhAspectRatioWrapper class="" :aspectRatio="$root.isBelowMd ? '329:338' : '1160:499'">
						<div class="InteriorHome__logosImage"></div>
					</MhAspectRatioWrapper>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import getUrlWithoutHostname from '@/helper/getUrlWithoutHostname.js'
	import MhAspectRatioWrapper from '@/components/vendor/MhAspectRatioWrapper/v1/MhAspectRatioWrapper.vue'

	export default {
		name: 'InteriorHome',
		components: {
			MhAspectRatioWrapper,
		},
		mixins: [],
		props: {
			guidePosts: {
				type: [Array, Boolean],
				default: false,
				required: false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			getUrlWithoutHostname( url ){
				return getUrlWithoutHostname( url )
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.InteriorHome { // debug
		[showBorders2] & {
			&__cover { background-color: fade(blue, 25); }
			&__guidesList { background-color: fade(red, 25); }
			&__guidesListItem { background-color: fade(red, 50); }
			&__introText { background-color: fade(green, 25); }
			&__introText > * { background-color: fade(green, 50); }
		}
	}
	.InteriorHome { // vars
		--sampleVar : red;
	}
	.InteriorHome { // layout
		&__cover {}
		&__coverImage { position: absolute; inset: 0; }

		&__introText { display: grid; gap: var(--app-gridGap); grid-template-columns: repeat(3, 1fr); }
		&__introTextInner { grid-column: 2 / span 2; @media @mq[sm] { grid-column: 1 / span 3; } }

		&__guidesList {
			//padding: 0; // handled via .hSpace--app
			list-style: none;

			display: grid;
			gap: var(--app-gridGap);
			grid-template-columns : repeat(3, 1fr);

			@media @mq[sm] { grid-template-columns : repeat(2, 1fr); }
		}
		&__guidesListItem {}

		&__guide { display: flex; flex-direction: column; height: 100%; }
		&__guideThumbnail { position: absolute; inset: 0; height: 100%; width: 100%; }
		&__guideTitle { flex-grow: 1; }

		&__logosImage { position: absolute; inset: 0; }

		// vertical spacing
		font-size: 16px; @media @mq[sm] { grid-column: 1 / span 3; }
		&__cover { padding-top: 2em; padding-bottom: 1em; @media @mq[sm] { padding-top: 1.5em; padding-bottom: 1em; } }
		&__introText { padding-top: 2em; padding-bottom: 2em; }
		&__guidesList { padding-top: 1em; padding-bottom: 4em; @media @mq[sm] { padding-top: 1em; padding-bottom: 2em; } }
		&__logos { padding-top: 4em; padding-bottom: 8em; @media @mq[sm] { padding-top: 2em; padding-bottom: 4em; } }
	}
	.InteriorHome { // styling
		background-color: #f087aa;

		&__coverImage {
			background-image: url('./assets/HomepageCover.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top left;

			@media @mq[sm] { background-image: url('./assets/HomepageCover--sm.png'); }
		}

		&__introText {
			font-family: 'Söhne';
			font-weight: 500;
			font-size: 28px;
			line-height: 1.25em;
			hyphens: auto;

			@media @mq[sm] { font-size: 20px; hyphens: auto; }
		}

		&__guide { transition: all 0.2s ease; &:hover { transform: translateY(-0.5em); } }
		&__guide { color: inherit; }
		&__guideThumbnailWrapper { background-color: fade(black, 25); }
		&__guideThumbnail { object-fit: cover; }
		&__guideTitle {
			padding: 0.65em 0.75em 1.25em 0.75em;
			background-color: fade(white, 100);
			font-family: 'Söhne Breit';
			font-weight: 900;
			font-size: 20px;
			line-height: 1.1em;

			@media @mq[sm] { font-size: 14px; hyphens: auto; }
		}

		&__logos {
			background-color: white;
		}
		&__logosImage {
			background-image: url('./assets/LogoLeiste.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top left;

			@media @mq[sm] { background-image: url('./assets/LogoLeiste--sm.png'); }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
